import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Select, { components } from 'react-select'
import Paper from '@material-ui/core/Paper'
import useApi from 'hooks/useApi'

const MultiImageValue = (props) => {
  const value = props.getValue()[props.index]
  const id = props.selectProps.selectProps.id
  const api = useApi()
  const image = api.getMediaUrlFromId(value?._id)
  // const image = `${mediaUrl}/${id}/images/${value._id}.${value.ext}`
  return (
    <components.MultiValue {...props}>
      <Tooltip title={value.name}>
        <div>
          <img
            style={{ width: '100px', height: '100px' }}
            src={image}
            alt={value.name}
          />
        </div>
      </Tooltip>
    </components.MultiValue>
  )
}

const SingleImageValue = (props) => {
  const value = props.data
  console.log(value)
  const id = props.selectProps.selectProps.id
  const api = useApi()
  const image = api.getMediaUrlFromId(value?._id)
  // const image = `${mediaUrl}/${id}/images/${value}`
  return value ? (
    <div style={{ height: '4rem' }}>
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <img
              style={{ width: '100px', height: '100px' }}
              src={image}
              alt={props.data.name}
            />
          </div>
          <div style={{ paddingLeft: '1rem' }}>{props.data.name}</div>
        </div>
      </components.SingleValue>
    </div>
  ) : null
}

const ImageOption = (props) => {
  const id = props.selectProps.selectProps.id
  const { data } = props
  console.log({ data })
  const api = useApi()
  const image = api.getMediaUrlFromId(data._id)
  // const image = `${mediaUrl}/${id}/images/${props.data._id}.${props.data.ext}`
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          style={{ width: '50px', height: '50px' }}
          src={image}
          alt={props.data.name}
        />
        <div style={{ paddingLeft: 50 }}>{props.data.name}</div>
      </div>
    </components.Option>
  )
}

export const PaperImage360Select = ({
  data,
  tourId,
  images,
  onImageChange,
  multi = true
}) => {
  const classes = useStyles()
  return (
    <Paper elevation={1} className={classes.mPaper}>
      <h3>360 Bilder</h3>
      {data?.images360 && (
        <div>
          <Select
            classNamePrefix='react-select'
            placeholder={'Välj 360 bilder...'}
            value={data.images360 || null}
            selectProps={{ id: tourId }}
            getOptionValue={(option) => option._id}
            getLabelValue={(option) => option.name}
            components={{
              Option: ImageOption,
              MultiValue: MultiImageValue,
              SingleValue: SingleImageValue
            }}
            onChange={onImageChange}
            options={images}
            menuPlacement='auto'
            openMenuOnClick={true}
            closeMenuOnSelect={false}
            isMulti={multi}
            isSearchable={false}
          />
        </div>
      )}
    </Paper>
  )
}

export const PaperImageSelect = ({
  data,
  tourId,
  images,
  onImageChange,
  multi = true
}) => {
  const classes = useStyles()
  return (
    <Paper elevation={1} className={classes.mPaper}>
      <h3>Bilder</h3>
      {data?.images && (
        <div>
          <Select
            classNamePrefix='react-select'
            placeholder={'Välj bilder...'}
            value={data.images || null}
            selectProps={{ id: tourId }}
            getOptionValue={(option) => option._id}
            getLabelValue={(option) => option.name}
            components={{
              Option: ImageOption,
              MultiValue: MultiImageValue,
              SingleValue: SingleImageValue
            }}
            onChange={onImageChange}
            options={images}
            menuPlacement='auto'
            openMenuOnClick={true}
            closeMenuOnSelect={false}
            isMulti={multi}
            isSearchable={false}
          />
        </div>
      )}
    </Paper>
  )
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },

  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},

  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  mDropper: {
    width: '80%',
    marginTop: 10,
    marginBottom: 10,
    padding: 20,
    paddingTop: 5,
    fontSize: 16
  }
})
