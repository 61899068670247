import React, { useEffect, useReducer, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TopMenu from './components/TopMenu'
import TourPageSideBar from './components/TourPageSideBar'
import { Outlet, useNavigate } from 'react-router'
import useApi from 'hooks/useApi'

const drawerWidth = 240

const TourPage = () => {
  const classes = useStyles()

  const api = useApi()
  const { route, tour } = api

  useEffect(() => {
    if (route.selected) {
      api.getTour(route.selected.tourId)
    }
  }, [])

  useEffect(() => {
    if (tour.dirty === true || route.dirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [tour.dirty, route.dirty])

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <TopMenu />
        <TourPageSideBar
          classes={classes}
          tour={tour.working}
          selectedRoute={route.selected}
        />
        <main className={classes.content}>
          <Toolbar />
          <div id='MainCont' className={classes.mainCon}>
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden'
  },
  appBar: {
    width: '100%',
    height: 60,
    color: '#444',
    marginLeft: drawerWidth,
    backgroundColor: '#ccdbf0',
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
    top: 47
  },
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxHeight: '100vh',
    overflow: 'auto'
  },
  mainCon: {
    align: 'left',
    textAlign: 'left',
    minHeight: 300
  },
  backBtn: {
    paddingLeft: 10,
    align: 'left',
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 500,
    marginLeft: -10,
    textTransform: 'none'
  },
  addBtn: {
    align: 'center',
    textAlign: 'center',
    fontSize: 14,
    width: '80%',
    textTransform: 'none',
    margin: 'auto',
    backgroundColor: '#528adb',
    color: 'white',
    marginBottom: 10
  },
  sPaper: {
    width: drawerWidth,
    marginBottom: 20,
    padding: 5,
    paddingTop: 5,
    backgroundColor: 'default',
    flexShrink: 0
  },
  alertButton: {
    backgroundColor: 'orange',
    color: 'blue',
    margin: '2px 10px 2px 10px'
  }
}))

export default TourPage
