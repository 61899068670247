import {
  SELECT_ROUTE,
  DESELECT_ROUTE,
  REMOVE_ROUTE,
  CREATE_ROUTE,
  UPDATE_ROUTE,
  RESET_ROUTES_STATE,
  UPDATE_ROUTES_FROM_SERVER
} from 'symbols'

const routeReducer = (state, action) => {
  const { working } = state
  const { type, payload } = action
  const idPredicate = (v) => v._id === id
  const { _id: id = null } = payload

  switch (type) {
    case SELECT_ROUTE: {
      return {
        ...state,
        selected: working.tours.find(idPredicate),
        dirty: false
      }
    }

    case DESELECT_ROUTE: {
      return {
        ...state,
        selected: null,
        dirty: false
      }
    }

    case UPDATE_ROUTE: {
      const tours = working.tours.map((v) => {
        return v._id === id ? { ...v, ...payload } : { ...v }
      })

      return {
        ...state,
        working: { ...working, tours },
        selected: payload,
        dirty: true
      }
    }

    case REMOVE_ROUTE: {
      return {
        ...state,
        working: { ...working },
        dirty: true
      }
    }

    case CREATE_ROUTE: {
      return {
        ...state,
        working: { ...working },
        selected: payload,
        dirty: true
      }
    }

    case RESET_ROUTES_STATE: {
      return {
        ...state,
        working: { ...state.original },
        selected: state.original.tours.find(i => i._id ===state.selected._id),
        dirty: false
      }
    }

    case UPDATE_ROUTES_FROM_SERVER: {
      return { ...state, ...payload }
    }

    default:
      throw new Error(`action.type not found ${action.type.toString()}`)
  }
}

export default routeReducer
