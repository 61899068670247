import {
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  REMOVE_ACCOUNT,
  RESET_ACCOUNT_STATE,
  UPDATE_ACCOUNT_FROM_SERVER
} from 'symbols'

const accountReducer = (state, action) => {
  const { working } = state
  const { type, payload } = action
  const idPredicate = (v) => v._id === id
  const { _id: id = null } = payload

  switch (type) {
    case CREATE_ACCOUNT: {
      return {
        ...state,
        working: { ...working },
        dirty: true
      }
    }

    case UPDATE_ACCOUNT: {
      return {
        ...state,
        working: { ...working },
        dirty: true
      }
    }

    case REMOVE_ACCOUNT: {
      return {
        ...state,
        working: { ...working },
        dirty: true
      }
    }

    case RESET_ACCOUNT_STATE: {
      return { ...state, working: { ...state.original }, dirty: false }
    }

    case UPDATE_ACCOUNT_FROM_SERVER: {
      return { ...state, ...payload }
    }

    default:
      throw new Error(`action.type not found ${action.type.toString()}`)
  }
}

export default accountReducer
