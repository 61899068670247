import React, { useContext, useState, useEffect, useCallback } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel
} from '@material-ui/core'
import { FormGroup } from '@material-ui/core'
import useApi from 'hooks/useApi'
// import * as languageJSON from './languages.json'

const LanguageDialog = ({ open, onCancel, onClose }) => {
  const api = useApi()
  const [languages, setLanguages] = useState([])

  useEffect(() => {
    setLanguages([...api.route.selected.languages])
  }, [open, api.route.selected.languages])

  const onChange = (item) => () => {
    const updatedLanguages = languages.map((i) =>
      i.language_code === item.language_code ? { ...i, enable: !i.enable } : i
    )
    setLanguages(updatedLanguages)
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='sm'
      aria-labelledby='confirmation-dialog-languages'
      open={open}
    >
      <DialogTitle id='confirmation-dialog-languages'>Språk</DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          <FormLabel component='legend'>
            Välj språk för automatisk översättning
          </FormLabel>
          <FormGroup>
            {languages.map((i) => (
              <LanguageCheckbox
                language={i}
                key={i.language_code}
                onChange={onChange(i)}
              />
            ))}
          </FormGroup>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color='primary'>
          Avbryt
        </Button>
        <Button onClick={onClose(languages)} color='primary'>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const LanguageCheckbox = ({ language, onChange }) => {
  const { language_code, language_en } = language
  const isDefaultLanguage = language_code === 'sv'
  const languageLabel = isDefaultLanguage
    ? `${language_en} - Primärt språk`
    : language_en

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isDefaultLanguage ? true : language.enable}
          disabled={isDefaultLanguage}
          onChange={onChange}
          name={language_code}
        />
      }
      label={languageLabel}
    />
  )
}

export default LanguageDialog
