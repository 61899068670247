import React, { useState } from 'react'
import { withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import {
  Input,
  FormControl,
  FormHelperText,
  InputLabel,
  FormGroup
} from '@material-ui/core'

import { useNavigate } from 'react-router'
import useApi from 'hooks/useApi'

const Settings = () => {
  const classes = useStyles()
  const [oldPassword, setOldPassword] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const navigate = useNavigate()
  const api = useApi()
  const { account } = api
  const { getLanguageField } = api

  const onSubmit = (e) => {
    e.preventDefault()
    if (password1 === password2) {
      api.changePassword(oldPassword, password1)
    }
  }

  const validateForm = () => {
    return (
      oldPassword.length > 0 &&
      password1.length > 0 &&
      password2.length > 0 &&
      password1 === password2
    )
  }

  const onReturnToTourList = () => {
    navigate('/projects')
  }

  const onLogOut = async () => {
    api.logout()
  }

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  }))(TableCell)

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }))(TableRow)

  console.log(account)
  console.log(api.route)
  return (
    <div>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <div>
              <Typography variant='h6' noWrap>
                <Button
                  className={classes.backBtn}
                  onClick={onReturnToTourList}
                >
                  &#8678; Dina inställningar
                </Button>
              </Typography>
            </div>
            <div>
              <Typography variant='h6' noWrap>
                <Button className={classes.backBtn} onClick={onLogOut}>
                  Logga ut
                </Button>
              </Typography>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <h3>UserPage {account.user.id}</h3>
        <br />
        <br />
        <br />
        <Paper elevation={3}>
          <div
            style={{
              display: 'flex',
              marginLeft: '10px',
              marginBottom: '-10px'
            }}
          >
            <h4>Ändra lösenord:</h4>
          </div>
          <form
            style={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              padding: '20px',
              borderStyle: 'solid #333',
              borderRadius: '5px',
              borderColor: '#999',
              borderWidth: '10px'
            }}
            onSubmit={onSubmit}
          >
            <FormControl required className={classes.InputField}>
              <InputLabel htmlFor='oldPassword'>Nuvarande lösenord</InputLabel>
              <Input
                id='oldPassword'
                type='password'
                aria-describedby='forgot-password'
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </FormControl>
            <FormControl required className={classes.InputField}>
              <InputLabel htmlFor='password1'>Nytt lösenord</InputLabel>
              <Input
                id='password1'
                type='password'
                aria-describedby='forgot-password'
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
              />
            </FormControl>
            <FormControl required className={classes.InputField}>
              <InputLabel htmlFor='password2'>Nytt lösenord igen</InputLabel>
              <Input
                id='password2'
                type='password'
                aria-describedby='forgot-password'
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
            </FormControl>
            <div
              style={{ display: 'flex', marginTop: 15, alignItems: 'flex-end' }}
            >
              <Button
                type='submit'
                variant='contained'
                disabled={!validateForm()}
              >
                Ändra
              </Button>
            </div>
          </form>
        </Paper>

        <br />
        <br />
        <br />
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Dina rundor</StyledTableCell>
                <StyledTableCell align='right'>Antal startade</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {api.route.working.tours?.map((val, index) => {
                return (
                  <StyledTableRow>
                    <StyledTableCell align='left'>
                      {
                        val.title.data.find((i) => i.language_code === 'sv')
                          ?.text
                      }
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {val.viewStats}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    width: '70%'
  },
  table2: {
    width: 400
  },
  appBar: {
    width: '100%',
    height: 60,
    color: '#444',
    backgroundColor: '#ccdbf0',
    zIndex: theme.zIndex.drawer + 1
  },

  backBtn: {
    align: 'left',
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 500,
    marginLeft: -10,
    textTransform: 'none'
  },
  InputField: {
    marginRight: 20
  }
}))

export default Settings
