import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Select, { components } from 'react-select'
import Paper from '@material-ui/core/Paper'
import useApi from 'hooks/useApi'

const SingleVideoValue = (props) => {
  const id = props.selectProps.selectProps.id
  const { data } = props
  console.log({ data })
  const api = useApi()
  const video = api.getMediaUrlFromId(data._id)
  // const video = `${mediaUrl}/${id}/videos/${props.data._id}.${props.data.ext}`
  return (
    <div style={{ height: '200px' }}>
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <video
              style={{ height: '200px', width: '300px' }}
              controls
              playsInline
            >
              <source src={video} type='video/mp4' />
            </video>
          </div>
          <div style={{ paddingLeft: '1rem' }}>{props.data.name}</div>
        </div>
      </components.SingleValue>
    </div>
  )
}

const VideoOption = (props) => {
  return (
    <components.Option {...props}>
      <div>{props.data.name}</div>
    </components.Option>
  )
}

export const PaperVideoSelect = ({ data, tourId, videos, onVideoChange }) => {
  const classes = useStyles()
  console.log({ data })
  console.log({ videos })
  return (
    <Paper elevation={1} className={classes.mPaper}>
      <h3>Film</h3>
      {data?.videos && (
        <div>
          <Select
            classNamePrefix='react-select'
            placeholder={'Välj film...'}
            value={data.videos[0] || null}
            selectProps={{ id: tourId }}
            getOptionValue={(option) => `${option._id}.${option.ext}`}
            getLabelValue={(option) => option.name}
            components={{
              Option: VideoOption,
              SingleValue: SingleVideoValue
            }}
            isClearable={true}
            onChange={onVideoChange}
            options={videos}
            menuPlacement='auto'
            openMenuOnClick={true}
            closeMenuOnSelect={true}
          />
        </div>
      )}
    </Paper>
  )
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },

  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},

  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  mDropper: {
    width: '80%',
    marginTop: 10,
    marginBottom: 10,
    padding: 20,
    paddingTop: 5,
    fontSize: 16
  }
})
