import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  FormGroup
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import MUICookieConsent from 'material-ui-cookie-consent'
import ReactHtmlParser from 'react-html-parser'
import Logo from 'assets/logo3.png'
import useApi from 'hooks/useApi'
import BackImgA from 'assets/back4a.jpg'
import BackImgB from 'assets/back4b.png'

const Email = ({ email, setEmail, onSubmit }) => {
  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onSubmit={onSubmit}
    >
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <h1>Åteställ lösen</h1>
      </div>
      <FormControl required>
        <InputLabel htmlFor='email'>Email</InputLabel>
        <Input
          key={'email'}
          id='email'
          type='email'
          aria-describedby='enter email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormHelperText id='set-email'></FormHelperText>
      </FormControl>
      <div style={{ display: 'flex', marginTop: 30, alignItems: 'flex-end' }}>
        <Button type='submit' variant='contained'>
          Skicka email address
        </Button>
      </div>
    </form>
  )
}

const PasswordAndCode = ({
  password1,
  setPassword1,
  password2,
  setPassword2,
  code,
  setCode,
  onSubmit
}) => {
  const validateForm = useCallback(() => {
    return (
      password1.length > 0 &&
      password2.length > 0 &&
      password1 === password2 &&
      code > 5
    )
  }, [password1, password2, code])

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onSubmit={onSubmit}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <h1>Återställ Lösen</h1>
        Kolla din mail för verificeringskod
      </div>
      <FormGroup>
        <FormControl required>
          <InputLabel htmlFor='password1'>Lösenord</InputLabel>
          <Input
            id='password1'
            type='password'
            aria-describedby='choose-password'
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
          />
          <FormHelperText id='choose-pasword'></FormHelperText>
        </FormControl>
        <FormControl required error={password1 !== password2}>
          <InputLabel htmlFor='password2'>Upprepa lösenordet</InputLabel>
          <Input
            id='password2'
            type='password'
            aria-describedby='repeat-password'
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
          <FormHelperText id='reset-pasword'></FormHelperText>
        </FormControl>
      </FormGroup>
      <FormControl required>
        <InputLabel htmlFor='code'>Kod från e-post</InputLabel>
        <Input
          id='code'
          type='text'
          aria-describedby='code'
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <FormHelperText id='reset-code'></FormHelperText>
      </FormControl>
      <div style={{ display: 'flex', marginTop: 30, alignItems: 'flex-end' }}>
        <Button type='submit' variant='contained' disabled={!validateForm()}>
          Ändra lösen
        </Button>
      </div>
    </form>
  )
}

const ResetPassword = ({ setResetMail }) => {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [password1, setPassword1] = useState(undefined)
  const [password2, setPassword2] = useState(undefined)
  const [code, setCode] = useState(undefined)
  const api = useApi()

  const onSubmit = async (e) => {
    e.preventDefault()
    const result = await api.resetPassword(email, password1, code)
    if (result?.status === 'success') {
      if (result?.data === 'password changed') {
        alert('Lösen bytt - du kan nu logga in')
        setResetMail(false)
      } else {
        console.log('we should now show change password form')
        setPassword1('')
        setPassword2('')
        setCode('')
        setEmailSent(true)
      }
    }
  }

  return !emailSent ? (
    <Email
      email={email}
      setEmail={setEmail}
      setEmailSent={setEmailSent}
      onSubmit={onSubmit}
    />
  ) : (
    <PasswordAndCode
      password1={password1}
      setPassword1={setPassword1}
      password2={password2}
      setPassword2={setPassword2}
      code={code}
      setCode={setCode}
      onSubmit={onSubmit}
    />
  )
}

const LogIn = ({ setSignUp, setResetMail, onLogin }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // const { dispatchAccount: dispatch } = useContext(AccountContext)

  const navigate = useNavigate()
  const api = useApi()

  useEffect(() => {
    if (api.account.user?.id != null) {
      navigate('/projects')
    }
  }, [api.account.user])

  const onSubmit = (e) => {
    e.preventDefault()
    api.login(email, password)
  }
  const validateForm = () => {
    return email.length > 0 && password.length > 0
  }

  return (
    <Paper elevation={3}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px'
        }}
        onSubmit={onSubmit}
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <h4>Logga in</h4>
        </div>
        <FormControl required>
          <InputLabel htmlFor='email'>Email address</InputLabel>
          <Input
            id='email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-describedby='email-never-share'
          />
          <FormHelperText id='email-never-share'></FormHelperText>
        </FormControl>
        <FormControl required>
          <InputLabel htmlFor='password'>Lösenord</InputLabel>
          <Input
            id='password'
            type='password'
            aria-describedby='forgot-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormHelperText id='forgot-pasword'>
            Om du glömt ditt lösen ord klicka{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setResetMail(true)}
            >
              här
            </span>
          </FormHelperText>
        </FormControl>
        <div style={{ display: 'flex', marginTop: 30, alignItems: 'flex-end' }}>
          <Button type='submit' variant='contained' disabled={!validateForm()}>
            Logga In
          </Button>
          <div style={{ marginLeft: 10 }} className='MuiFormHelperText-root'>
            Inte registrerad än?
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setSignUp(true)}
            >
              skapa konto
            </span>
          </div>
        </div>
      </form>
    </Paper>
  )
}

const SignUp = ({ setSignUp }) => {
  const [organization, setOrganization] = useState('')
  const [email, setEmail] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const api = useApi()

  const validateForm = () => {
    return (
      organization.length > 0 &&
      email.length > 0 &&
      password1.length > 0 &&
      password2.length > 0 &&
      password1 === password2
    )
  }

  const onSubmit = (e) => {
    e.preventDefault()
    api.signup(email, password1, password2, organization)
  }

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onSubmit={onSubmit}
    >
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <h1>Sign Up</h1>
      </div>
      <FormControl required>
        <InputLabel htmlFor='organization'>Välj ett kontonamn</InputLabel>
        <Input
          id='organization'
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
          aria-describedby='organization-help'
        />
        <FormHelperText id='organization-help'></FormHelperText>
      </FormControl>
      <FormControl required>
        <InputLabel htmlFor='email'>Email address</InputLabel>
        <Input
          id='email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          aria-describedby='email-never-share'
        />
        <FormHelperText id='email-never-share'></FormHelperText>
      </FormControl>
      <FormGroup>
        <FormControl required>
          <InputLabel htmlFor='password'>Lösenord</InputLabel>
          <Input
            id='password1'
            type='password'
            aria-describedby='choose-password'
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
          />
          <FormHelperText id='choose-pasword'></FormHelperText>
        </FormControl>
        <FormControl required error={password1 !== password2}>
          <InputLabel htmlFor='password'>Upprepa lösenordet</InputLabel>
          <Input
            id='password2'
            type='password'
            aria-describedby='forgot-password'
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
          <FormHelperText id='forgot-pasword'></FormHelperText>
        </FormControl>
      </FormGroup>
      <div style={{ display: 'flex', marginTop: 30, alignItems: 'flex-end' }}>
        <Button type='submit' variant='contained' disabled={!validateForm()}>
          Signa Upp
        </Button>
        <div style={{ marginLeft: 10 }} className='MuiFormHelperText-root'>
          Redan användare?{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setSignUp(false)}
          >
            Logga in
          </span>
        </div>
      </div>
    </form>
  )
}

const Authenticate = () => {
  const [isSignUp, setSignUp] = useState(false)
  const [resetMail, setResetMail] = useState(false)
  const navigate = useNavigate()

  const onLogin = () => navigate('/projects')

  return (
    <div style={{ height: '100vh' }}>
      <MUICookieConsent
        cookieName='cookieConsent'
        cookieValue='accepted'
        componentType='Snackbar' // default value is Snackbar
        message={ReactHtmlParser(
          'This site uses cookies in order to work. You can read our <a style="color:white;" target="_blank" href="#">privacy policy</a> for more information. By continuing to use this site you automatically agree to this.'
        )}
        snackbarAnchor={{ horizontal: 'center', vertical: 'center' }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '30vh',
          marginBottom: 50,
          alignItems: 'center'
        }}
      >
        <img
          alt=''
          style={{
            width: '20.5vh'
          }}
          src={Logo}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {resetMail ? (
          <ResetPassword setResetMail={setResetMail} />
        ) : isSignUp ? (
          <SignUp setSignUp={setSignUp} />
        ) : (
          <LogIn
            setSignUp={setSignUp}
            onLogin={onLogin}
            setResetMail={setResetMail}
          />
        )}
      </div>
      <div
        style={{
          marginTop: '5vh'
        }}
      >
        Läs mer om Shuttle{' '}
        <a href='http://www.do-fi.se/shuttle/' target='_blank'>
          här
        </a>
      </div>

      <img
        alt=''
        style={{
          width: '50vh',
          height: '80vh',
          position: 'fixed',
          bottom: '0',
          left: '0'
        }}
        src={BackImgA}
      />
      <img
        alt=''
        style={{
          width: '40vh',
          height: '70vh',
          position: 'fixed',
          bottom: '0',
          right: '0'
        }}
        src={BackImgB}
      />
    </div>
  )
}

export default Authenticate
