import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import useApi from 'hooks/useApi'
import { useNavigate } from 'react-router'

const MediaButtons = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const api = useApi()
  return (
    <>
      <h4>Media</h4>
      <Button
        className={classes.addBtn}
        variant='contained'
        color='default'
        onClick={() => {
          api.viewMedia('IMAGE')
          navigate('media')
        }}
      >
        Bilder
      </Button>
      <Button
        className={classes.addBtn}
        variant='contained'
        color='default'
        onClick={() => {
          api.viewMedia('SOUND')
          navigate('media')
        }}
      >
        Ljud
      </Button>
      <Button
        className={classes.addBtn}
        variant='contained'
        color='default'
        onClick={() => {
          api.viewMedia('VIDEO')
          navigate('media')
        }}
      >
        Filmer
      </Button>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  addBtn: {
    align: 'center',
    textAlign: 'center',
    fontSize: 14,
    width: '80%',
    textTransform: 'none',
    margin: 'auto',
    backgroundColor: '#528adb',
    color: 'white',
    marginBottom: 10
  }
}))
export default MediaButtons
