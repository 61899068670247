import React, { useContext, useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Select, { components } from 'react-select'
import GoogleMapContainer from '../../gMaps'
import Paper from '@material-ui/core/Paper'
import useDebounce from '../../hooks/useDebounce'
import useApi from 'hooks/useApi'

const mapW = 400
const mapH = 400

export const PaperMapAndLocation = ({
  data,
  onMapsMarkerDragEnd,
  onLatitudeChange,
  onLongitudeChange,
  onGetCurrentLocation,
  onRadiusChange
}) => {
  const classes = useStyles()
  const api = useApi()
  const { route, getLanguageField } = api
  console.log({ route })

  return (
    <>
      <div>
        <GoogleMapContainer
          latitude={
            data?.point?.coordinates[1] ??
            api.route?.selected?.point?.coordinates[1] ??
            0
          }
          longitude={
            data?.point?.coordinates[0] ??
            api.route?.selected?.point?.coordinates[0] ??
            0
          }
          width={mapW}
          height={mapH}
          onMarkerDragEnd={onMapsMarkerDragEnd}
        />
      </div>
      <div className={classes.underMap}>
        <Button
          onClick={onGetCurrentLocation}
          variant='contained'
          color='primary'
        >
          Use Current Location
        </Button>
        <h3>Latitude:</h3>
        <input
          className={classes.singleLineTxtField}
          type='text'
          id='lat'
          name='lat'
          value={data?.point?.coordinates[1]}
          onChange={onLatitudeChange}
        />
        <h3>Longitude:</h3>
        <input
          className={classes.singleLineTxtField}
          type='text'
          id='lng'
          name='lng'
          value={data?.point?.coordinates[0]}
          onChange={onLongitudeChange}
        />
        <h3>Radie:</h3>
        <input
          className={classes.singleLineTxtField}
          type='text'
          id='rad'
          name='rad'
          value={data.radius}
          onChange={onRadiusChange}
        />
      </div>
    </>
  )
}

export const PaperSubCategory = ({ data, onSubCategoryChange }) => {
  const classes = useStyles()
  const api = useApi()
  const { getLanguageField } = api
  return (
    <Paper elevation={1} className={classes.mPaper}>
      <h3>SubKategori:</h3>
      <input
        className={classes.singleLineTxtField}
        type='text'
        id='subCategory'
        name='subCategory'
        value={getLanguageField(data.subcategory)}
        onChange={onSubCategoryChange}
      />
    </Paper>
  )
}

export const PaperCategory = ({ data, categories, onCategoryChange }) => {
  const classes = useStyles()
  const api = useApi()
  const { route, getLanguageField } = api
  console.log(route)
  const type = getLanguageField(route.selected.title)
  console.log('Paper category ... type')
  console.log(type)
  return (
    <Paper elevation={1} className={classes.mPaper}>
      <h3>Kategori:</h3>
      <select name='category' id='category' onChange={onCategoryChange}>
        {categories
          ?.filter((i) => i.name === type)
          ?.map((c) => {
            console.log(c)
            return c.values.map((i) => {
              console.log(i)
              const selected = i === data.category ? true : false
              return (
                <option key={i} value={i} selected={selected}>
                  {i}
                </option>
              )
            })
          })}
      </select>
    </Paper>
  )
}

export const PaperEditor = ({ data }) => {
  const classes = useStyles()
  const api = useApi()
  const { tour, getLanguageField } = api
  const initialState = {
    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(getLanguageField(data.htmlContent))
      )
    ),
    dirty: false
  }

  const [state, setState] = useState(initialState)

  const debouncedEditorSave = useDebounce(state, 300)

  useEffect(() => {
    console.log('inside first use effect')
    setState({
      ...state,
      editorState: EditorState.moveFocusToEnd(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(getLanguageField(data.htmlContent))
          )
        )
      ),
      dirty: false
    })
  }, [data._id])

  useEffect(() => {
    if (debouncedEditorSave && state.dirty === true) {
      console.log('updating from editor')
      const description = stateToHTML(
        debouncedEditorSave.editorState.getCurrentContent()
      )
      api.updateTourPointDescription(description, data)
      setState({ ...state, dirty: false })
    }
  }, [debouncedEditorSave])

  const onEditorStateChange = (editorState) => {
    if (editorState._immutable.lastChangeType != null) {
      console.log('we are in editorstate')
      console.log(stateToHTML(editorState.getCurrentContent()))
      setState({
        ...state,
        editorState,
        dirty: true
      })
    }
  }

  return (
    <Paper elevation={1} className={classes.mPaper}>
      <h3>Text:</h3>
      <Editor
        toolbar={{
          options: ['inline', 'list', 'fontSize'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline']
          }
        }}
        toolbarClassName='toolbarClassName'
        wrapperClassName='home-wrapper'
        editorClassName='home-editor'
        editorState={state.editorState}
        //value={data.htmlContent.sv}
        onEditorStateChange={onEditorStateChange}
        defaultValue={getLanguageField(data.htmlContent)}
      />
    </Paper>
  )
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  r1L: {
    flex: `calc(100% - ${mapW}px - 10px)`,
    marginRight: 20
  },
  r1R: {
    flex: mapW,
    marginTop: 0,
    marginRight: '0px',
    flexDirection: 'column'
  },
  underMap: {
    marginTop: mapH + 20,
    width: '100%'
  },

  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },

  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},

  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  mDropper: {
    width: '80%',
    marginTop: 10,
    marginBottom: 10,
    padding: 20,
    paddingTop: 5,
    fontSize: 16
  }
})
