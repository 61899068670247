import React, { useContext, useState, useEffect, useCallback } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel
} from '@material-ui/core'
import { FormGroup } from '@material-ui/core'
import useApi from 'hooks/useApi'

const PathPointDialog = ({ open, tourObjects, onCancel, onClose }) => {
  const api = useApi()
  const { places } = api.tour.working
  const [points, setPoints] = useState([])

  const compare = (a, b) => {
    const f = tourObjects?.findIndex((i) => i === a.data._id)
    const l = tourObjects?.findIndex((i) => i === b.data._id)
    if (f === -1 && l === -1) return 0
    if (f === -1) return 1
    if (l === -1) return -1
    return f - l
  }

  useEffect(() => {
    setPoints(
      [...places]
        .map((i) => {
          console.log(i)
          return {
            enable: !!tourObjects?.includes(i._id),
            data: i
          }
        })
        .sort(compare)
    )
  }, [open, places, tourObjects])

  const onChange = (item) => () => {
    const updatedPoints = points.map((i) => {
      return i.data._id === item.data._id ? { ...i, enable: !item.enable } : i
    })
    setPoints(updatedPoints.sort(compare))
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='sm'
      aria-labelledby='confirmation-dialog-points'
      open={open}
    >
      <DialogTitle id='confirmation-dialog-points'>Punktar</DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          <FormLabel component='legend'>
            Välj punktar som är del av denna slinga
          </FormLabel>
          <FormGroup>
            {points.map((i) => (
              <PointsCheckbox
                point={i.data}
                checked={i.enable}
                key={i._id}
                onChange={onChange(i)}
              />
            ))}
          </FormGroup>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color='primary'>
          Avbryt
        </Button>
        <Button
          onClick={onClose(points.filter((i) => i.enable).map((i) => i.data))}
          color='primary'
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const PointsCheckbox = ({ point, checked, onChange }) => {
  const api = useApi()
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={api.getLanguageField(point.title)}
        />
      }
      label={api.getLanguageField(point.title)}
    />
  )
}

export default PathPointDialog
