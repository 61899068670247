import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Select, { components } from 'react-select'
import Paper from '@material-ui/core/Paper'
import useApi from 'hooks/useApi'

const SingleSoundValue = (props) => {
  const id = props.selectProps.selectProps.id
  // const sound = `${mediaUrl}/${id}/sounds/${props.data._id}.${props.data.ext}`
  const api = useApi()
  const sound = api.getMediaUrlFromId(props?.data._id)
  return (
    <div style={{ height: '4rem' }}>
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <audio controls>
              <source src={sound} type='audio/mpeg' />{' '}
            </audio>
          </div>
          <div style={{ paddingLeft: '1rem' }}>{props.data.name}</div>
        </div>
      </components.SingleValue>
    </div>
  )
}

const SoundOption = (props) => {
  return (
    <components.Option {...props}>
      <div>{props.data.name}</div>
    </components.Option>
  )
}

export const PaperSoundSelect = ({ data, tourId, sounds, onSoundChange }) => {
  const classes = useStyles()
  return (
    <Paper elevation={1} className={classes.mPaper}>
      <h3>Ljud</h3>
      {data?.sounds && (
        <div>
          <Select
            classNamePrefix='react-select'
            placeholder={'Välj ljud...'}
            value={data.sounds[0] || null}
            selectProps={{ id: tourId }}
            getOptionValue={(option) => option._id}
            getLabelValue={(option) => option.name}
            components={{
              Option: SoundOption,
              SingleValue: SingleSoundValue
            }}
            isClearable={true}
            onChange={onSoundChange}
            options={sounds}
            menuPlacement='auto'
            openMenuOnClick={true}
            closeMenuOnSelect={true}
          />
        </div>
      )}
    </Paper>
  )
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },

  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},

  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  mDropper: {
    width: '80%',
    marginTop: 10,
    marginBottom: 10,
    padding: 20,
    paddingTop: 5,
    fontSize: 16
  }
})
