// Actions - Tour
export const UPDATE_LANGUAGES = Symbol('UPDATE_LANGUAGES')
export const UPDATE_TOURPOINT = Symbol('UPDATE_TOURPOINT')
export const CHANGE_TOURPOINT_TYPE = Symbol('CHANGE_TOURPOINT_TYPE')
export const REORDER_TOURPOINTS = Symbol('REORDER_TOURPOINTS')
export const REMOVE_TOURPOINT = Symbol('REMOVE_TOURPOINT')
export const CREATE_TOURPOINT = Symbol('CREATE_TOURPOINT')
export const RESET_TOUR_STATE = Symbol('RESET_TOUR_STATE')
export const UPDATE_TOUR_FROM_SERVER = Symbol('UPDATE_TOUR_FROM_SERVER')
export const TOUR_MEDIA = Symbol('TOUR_MEDIA')
export const SELECT_TOUR_POINT = Symbol('SELECT_TOUR_POINT')

// Actions - Paths
export const CREATE_TOUR_PATH = Symbol('CREATE_TOUR_PATH')
export const UPDATE_TOUR_PATH = Symbol('UPDATE_TOUR_PATH')
export const REMOVE_TOUR_PATH = Symbol('REMOVE_TOUR_PATH')
export const REORDER_TOUR_PATH = Symbol('REORDER_TOUR_PATH')
export const SELECT_TOUR_PATH = Symbol('SELECT_TOUR_PATH')

// Actions - Route
export const SELECT_ROUTE = Symbol('SELECT_ROUTE')
export const DESELECT_ROUTE = Symbol('DESELECT_ROUTE')
export const CREATE_ROUTE = Symbol('CREATE_ROUTE')
export const REMOVE_ROUTE = Symbol('REMOVE_ROUTE')
export const UPDATE_ROUTE = Symbol('UPDATE_ROUTE')
export const RESET_ROUTES_STATE = Symbol('RESET_ROUTE_STATE')
export const UPDATE_ROUTES_FROM_SERVER = Symbol('UPDATE_ROUTES_FROM_SERVER')

// Actions - Account
export const CREATE_ACCOUNT = Symbol('CREATE_ACCOUNT')
export const REMOVE_ACCOUNT = Symbol('REMOVE_ACCOUNT')
export const UPDATE_ACCOUNT = Symbol('UPDATE_ACCOUNT')
export const RESET_ACCOUNT_STATE = Symbol('RESET_ACCOUNT_STATE')
export const UPDATE_ACCOUNT_FROM_SERVER = Symbol('UPDATE_ACCOUNT_FROM_SERVER')
