import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import ReactHtmlParser from 'react-html-parser'
import MissingImage from 'assets/Missing-image-232x150.png'
import { mediaUrl } from 'config.js'
import useApi from 'hooks/useApi'

const ToursListPage = () => {
  const classes = useStyles()
  const [openRemoveTourDialog, setOpenRemoveTourDialog] = useState(false)
  const [routeToRemove, setRouteToRemove] = useState()

  const navigate = useNavigate()
  const api = useApi()
  const { route } = api
  console.log(route)

  useEffect(() => {
    api.getRoutes()
  }, [])

  useEffect(() => {
    route.selected && navigate(`/project/${route.selected._id}`)
  }, [route.selected])

  const onClickUser = () => {
    navigate('/settings')
  }

  const onClickRemove = (value) => (event) => {
    event.stopPropagation()
    setRouteToRemove(value._id)
    setOpenRemoveTourDialog(true)
  }

  const onClickTour = (value) => () => {
    api.selectRoute(value)
  }

  const onClickNew = () => {
    api.createRoute()
  }

  const handleImageError = (e) => {
    e.target.onerror = null
    e.target.src = MissingImage
  }

  const handleDialogCancel = () => {
    setOpenRemoveTourDialog(false)
  }

  const handleDialogAccept = () => {
    setOpenRemoveTourDialog(false)
    api.deleteRoute(routeToRemove)
    setRouteToRemove(null)
  }

  return (
    <div>
      <Dialog
        open={openRemoveTourDialog}
        onClose={handleDialogCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Ta bort rundpunkt?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Genom att ta bort en rut försvinner all data som varit gjort och
            kopplat till den inklusiva alla punkter, media, uppladdade gps
            rundor etc{' '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} color='primary'>
            Ångra
          </Button>
          <Button onClick={handleDialogAccept} color='primary' autoFocus>
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
      />
      <link
        href='https://fonts.googleapis.com/css?family=Baloo+Da+2|Source+Sans+Pro&display=swap'
        rel='stylesheet'
      />
      <div className={classes.MainTitle}>Shuttle Editor</div>
      <div className={classes.settingsBar}>
        <div
          style={{
            display: 'flex',
            marginLeft: '10px',
            marginTop: 'auto',
            marginBottom: '10px',
            width: '100%'
          }}
        >
          <h4>Dina projekt</h4>
        </div>
        <div
          style={{
            justifyContent: 'right'
          }}
        >
          <Button
            onClick={onClickUser}
            style={{
              fontSize: 40,
              padding: 0
            }}
          >
            &#9881;
          </Button>
        </div>
      </div>
      <Grid container className={classes.mGrid} spacing={3}>
        <Grid item xs={3}>
          <Card className={classes.cards} elevation={5}>
            <CardActionArea onClick={onClickNew}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color='textSecondary'
                  gutterBottom
                />
                <CardMedia
                  className={classes.newImg}
                  image={
                    'https://pbs.twimg.com/profile_images/506394721812373504/IDmMstyJ.jpeg'
                  }
                  component='img'
                  title='Guide image'
                />
                <Typography
                  className={classes.intro}
                  color='textSecondary'
                  gutterBottom
                >
                  Lägg till nytt projekt
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        {route.working.tours?.map((val) => {
          return (
            <Grid item xs={3} key={val._id}>
              <Card className={classes.cards} elevation={5}>
                <CardActionArea onClick={onClickTour(val)}>
                  <CardContent>
                    <Button onClick={onClickRemove(val)} variant='contained'>
                      Remove
                    </Button>
                    <Typography
                      className={classes.title}
                      color='textSecondary'
                      gutterBottom
                    >
                      {val.title.data
                        .filter((i) => i.language_code === 'sv')
                        .map((i) => i.text)}
                    </Typography>

                    <CardMedia
                      className={classes.media}
                      image={`${mediaUrl}/${val.tourId}/images/${val.coverImage?._id}.${val.coverImage?.ext}`}
                      onError={handleImageError}
                      component='img'
                      title='Guide image'
                    />
                    <Typography
                      className={classes.intro}
                      color='textSecondary'
                      gutterBottom
                    >
                      {ReactHtmlParser(val.htmlContent.sv)}
                    </Typography>
                    <LabelContainer>
                      {val.published && <Published>Published</Published>}
                      {val.code.active && <Locked>{val.code.number}</Locked>}
                    </LabelContainer>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles({
  mGrid: {
    width: '75%',
    margin: 'auto'
  },
  cards: {
    height: 300
  },
  MainTitle: {
    fontSize: 25,
    color: 'black',
    marginTop: 10,
    marginBottom: 20
  },
  title: {
    fontSize: 16,
    color: 'black',
    height: 20
  },
  intro: {
    height: 110,
    fontSize: 14,
    overflow: 'hidden'
  },
  pos: {
    marginBottom: 12
  },
  media: {
    height: 140
  },
  newImg: {
    height: 160,
    width: 160,
    margin: 'auto',
    marginBottom: 20
  },
  openBtn: {
    marginBottom: 32,
    alignmentBaseline: 'right'
  },
  settingsBar: {
    display: 'flex',
    width: '75%',
    margin: 'auto',
    height: 60,
    color: '#444',
    backgroundColor: '#ccdbf0',
    alignItems: 'stretch'
  }
})

const LabelContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  bottom: 20px;
`

const Published = styled.div`
  position: absolute;
  background-color: green;
  color: white;
  transform: rotate(45deg);
  width: 100%;
  padding: 1px;
  left: -45%;
  bottom: 0%;
  font-weight: 900;
`

const Locked = styled.div`
  position: absolute;
  background-color: grey;
  color: white;
  transform: rotate(315deg);
  letter-spacing: 2px;
  width: 100%;
  padding: 1px;
  right: -45%;
  bottom: 0%;
  font-weight: 900;
`

export default ToursListPage
