import React, { Fragment, useState, useEffect } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/core/styles'
import { Lightbox } from 'react-modal-image'
import { Upload } from '@progress/kendo-react-upload'
import {
  IntlProvider,
  LocalizationProvider,
  loadMessages
} from '@progress/kendo-react-intl'
import { isEmpty } from 'lodash'

import svMessages from './upload_sv.json'
import './Upload.scss'

import MissingImage from 'assets/Missing-image-232x150.png'
import { ShuttleApi } from 'config.js'
import useApi from 'hooks/useApi'

loadMessages(svMessages, 'sv')

const fileStatuses = [
  'UploadFailed',
  'Initial',
  'Selected',
  'Uploading',
  'Uploaded',
  'RemoveFailed',
  'Removing'
]

const LargeImageModal = ({ content, onClose, images }) => (
  <Lightbox
    medium={`${images}/${content._id}.${content.ext}`}
    alt={content.name}
    onClose={onClose}
  />
)

const MediaPage = () => {
  const classes = useStyles()
  const api = useApi()

  const { route, tour } = api
  const { data } = api.tour

  const { mediaData, mediaType } = data

  const [state, setState] = useState({
    files: [],
    events: [],
    filePreviews: {}
  })

  const [shouldUpdate, setShouldUpdate] = useState(false)

  const [uploadErrors, setUploadErrors] = useState([])

  const mediaUrls = api.getMediaUrls()
  const { images, sounds, videos } = mediaUrls

  const handleImageError = (e) => {
    e.target.onerror = null
    e.target.src = MissingImage
  }

  useEffect(() => {
    const f = async () => {
      await api.getTour(route.selected.tourId)
      if (shouldUpdate) {
        // fetch(`${BurlovApi}/api/tour/${route.selected.tourId}`, {
        //   credentials: 'include'
        // })
        //   .then((res) => res.json())
        //   .then((res) => {
        //     dispatch({
        //       type: UPDATE_TOUR_FROM_SERVER,
        //       payload: {
        //         original: res,
        //         working: res,
        //         dirty: false
        //       }
        //     })
        //   })
        //   .then((res) => {
        api.selectMediaType(mediaType)
        // dispatch({ type: TOUR_MEDIA, payload: mediaType })
        setShouldUpdate(false)
      }
    }
    f()
  }, [shouldUpdate])

  const onDeleteMedia = async (mediaId) => {
    await api.removeMedia(mediaId)
    setShouldUpdate(true)
  }

  const onAdd = (event) => {
    setState({
      ...state
    })
    setShouldUpdate(false)
    const afterStateChange = () => {
      event.affectedFiles
        .filter((file) => !file.validationErrors)
        .forEach((file) => {
          const reader = new FileReader()

          reader.onloadend = (ev) => {
            setState({
              ...state,
              filePreviews: {
                ...state.filePreviews,
                [file.uid]: ev.target.result
              }
            })
          }

          reader.readAsDataURL(file.getRawFile())
        })
    }
    setState(
      {
        ...state,
        files: event.newState,
        events: [
          ...state.events,
          `File selected: ${event.affectedFiles[0].name}`
        ]
      },
      afterStateChange
    )
  }

  const onProgress = (event) => {
    setState({
      ...state,
      files: event.newState,
      events: [
        ...state.events,
        `On Progress: ${event.affectedFiles[0].progress} %`
      ]
    })
  }

  const onStatusChange = (event) => {
    const file = event.affectedFiles[0]

    const files = event.newState.filter((i) => i !== file)
    const response = event.response.response
    const status = response.status
    const statusText = response.statusText
    const data = response.data
    console.log(response)
    console.log(status)
    console.log(statusText)
    console.log(data)

    setState({
      ...state,
      files,
      events: [
        ...state.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`
      ]
    })
    setShouldUpdate(files.length === 0 ? true : false)
  }

  const onBeforeUpload = (event) => {
    event.additionalData.tourid = tour.working._id
    event.additionalData.mediatype = mediaType
  }

  const onBeforeRemove = (event) => {
    event.additionalData.tourid = tour.working._id
    event.additionalData.mediatype = mediaType
  }

  const onRemove = (event) => {
    const file = event.affectedFiles[0]

    setState({
      files: event.newState.filter((i) => i !== file),
      events: [
        ...state.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`
      ]
    })
  }

  const [lightbox, setLightbox] = useState({
    active: false,
    images: null,
    content: null,
    onClose: null
  })

  const openLightBox = ({ images, content }) =>
    setLightbox({ active: true, images, content, onClose: closeLightBox })

  const closeLightBox = () =>
    setLightbox({
      active: false,
      images: null,
      content: null,
      onClose: null
    })

  const InUse = ({ value }) =>
    !isEmpty(value) ? (
      <>
        Används i:{' '}
        {value.map((v) => (
          <div
            style={{
              textDecoration: 'underline',
              padding: 0,
              margin: 0,
              cursor: 'pointer'
            }}
            onClick={() => api.selectTourPoint(v)}
            key={v._id}
          >
            {v.title.sv}
          </div>
        ))}
      </>
    ) : null

  const field =
    (mediaType === 'IMAGE' && 'images') ||
    (mediaType === 'SOUND' && 'sounds') ||
    (mediaType === 'VIDEO' && 'videos')

  const inUse = (value) => {
    return tour.working.places.reduce((acc, cur) => {
      const file = cur[`${field}`].filter((i) => i._id === value._id)
      !isEmpty(file) && acc.push(cur)
      return acc
    }, [])
  }

  const isCoverImage = (v) => v._id === route.selected.guideImage

  const media = (
    (mediaData != null &&
      mediaData.filter((i) => i.contentTypeGroup === mediaType)) ||
    []
  ).map((v) => {
    const cover = isCoverImage(v)
    console.log('isCoverImage: ' + cover)
    const used = inUse(v)
    return {
      content: v,
      used,
      inUse: <InUse classes={classes.listItemText} value={used} />
    }
  })

  return (
    <>
      <AppBar className={classes.uploadBar}>
        <Toolbar>
          <LocalizationProvider language='sv'>
            <IntlProvider locale='sv'>
              <Upload
                className={classes.upload}
                style={{ position: 'sticky' }}
                batch={false}
                multiple={true}
                files={state.files}
                onAdd={onAdd}
                onRemove={onRemove}
                onProgress={onProgress}
                onStatusChange={onStatusChange}
                onBeforeUpload={onBeforeUpload}
                onBeforeRemove={onBeforeRemove}
                restrictions={{
                  allowedExtensions: [
                    '.jpg',
                    '.jpeg',
                    '.JPG',
                    '.png',
                    '.PNG',
                    '.mp3',
                    '.mp4',
                    '.mov'
                  ]
                }}
                withCredentials={true}
                saveUrl={`${ShuttleApi}/api/media/upload/`}
              />
            </IntlProvider>
          </LocalizationProvider>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        {lightbox.active && <LargeImageModal {...lightbox} />}
        <List className={classes.list}>
          {media.map(({ content, inUse, used }) => {
            return (
              <Fragment key={content._id}>
                <ListItem className={classes.listItem} key={content._id}>
                  {mediaType === 'IMAGE' && (
                    <div>
                      <Tooltip title='Se i fullskärm'>
                        <img
                          controls
                          src={`${images}/${content._id}.${content.ext}`}
                          onError={handleImageError}
                          onClick={() => openLightBox({ images, content })}
                          alt={content.name}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {mediaType === 'SOUND' && (
                    <div>
                      <audio controls>
                        <source
                          src={`${sounds}/${content._id}.${content.ext}`}
                          onError={handleImageError}
                          type='audio/mpeg'
                        />
                      </audio>
                    </div>
                  )}
                  {mediaType === 'VIDEO' && (
                    <div>
                      <video
                        controls
                        playsInline
                        style={{ width: '200px', height: '150px' }}
                      >
                        <source
                          src={`${videos}${content._id}.${content.ext}`}
                          onError={handleImageError}
                          type='video/mp4'
                        />
                      </video>
                    </div>
                  )}
                  <ListItemText
                    primary={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.listItemTextName}>
                          {content.name}
                        </div>
                        <div className={classes.listItemTextRed}>{inUse}</div>
                      </div>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip
                      title={
                        !isEmpty(used)
                          ? 'Du får ta bort använding på rundpunkterna innan du kan ta bort denna media-fil'
                          : 'Ta bort'
                      }
                    >
                      <span>
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          disabled={!isEmpty(used)}
                          onClick={() => onDeleteMedia(content._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Fragment>
            )
          })}
        </List>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  list: {},
  listItem: {
    '& img': {
      width: 100,
      height: 100,
      cursor: 'pointer'
    }
  },
  listItemTextName: {
    paddingLeft: 50,
    width: '50%'
  },
  listItemTextRed: {
    color: 'red',
    width: '50%'
  },
  uploadBar: {
    position: 'sticky',
    top: 62,
    paddingTop: 10,
    paddingBottom: 10,
    height: 'auto',
    color: '#444',
    backgroundColor: '#ccdbf0',
    zIndex: theme.zIndex.drawer + 1
  },
  upload: {
    width: '100%'
  }
}))

export default MediaPage
