import { useContext } from 'react'
import { AccountContext } from 'App'
import { ShuttleApi } from 'config'
import axios from 'axios'
import { UPDATE_ACCOUNT_FROM_SERVER } from '../symbols'

axios.defaults.baseURL = ShuttleApi
axios.defaults.withCredentials = true

const hydrate = () => {}

const login = async (email, password, dispatch) => {
  const result = await axios.post('/login', {
    email,
    password
  })
  if (result.data?.id) {
    dispatch({
      type: UPDATE_ACCOUNT_FROM_SERVER,
      payload: {
        user: result.data
      }
    })
  } else {
    alert('Failed username or password')
  }
}

const logout = (dispatch) => {
  axios.get(`${ShuttleApi}/api/logout`)
  dispatch({
    type: UPDATE_ACCOUNT_FROM_SERVER,
    payload: {
      user: null,
      isLoggedIn: false
    }
  })
}

const signup = (email, password1, password2, organization) => {
  axios
    .post(`${ShuttleApi}/signup`, { email, password1, password2, organization })
    .then((res) => {
      if (res.data?.status === 'success') {
        alert('Check your mail for verification email')
      }
      console.log(res)
    })
}

const resetPassword = async (email, password, code) => {
  const { data } = await axios.post(`${ShuttleApi}/reset-password`, {
    email,
    password: password,
    code
  })
  return data
}

const changePassword = async (oldPassword, password1) => {
  // const { data } = await axios.post(`${ShuttleApi}/reset-password`, {
  //   email,
  //   password: password,
  //   code
  // })
  // return data
  const { data } = await axios.post(`${ShuttleApi}/api/changepassword`, {
    oldPassword,
    password1
  })
  if (data.status === 'failed') {
    alert('Ditt gamla löseord stämde inte')
  } else {
    alert('Ditt löseord är ändrat')
  }
  console.log(data)
}

const useAccount = () => {
  const { account, dispatchAccount } = useContext(AccountContext)
  return {
    account,
    hydrate: () => hydrate(),
    login: async (email, password) =>
      await login(email, password, dispatchAccount),
    logout: () => logout(dispatchAccount),
    signup: (email, password1, password2, organization) =>
      signup(email, password1, password2, organization),
    resetPassword: async (email, password, code) =>
      await resetPassword(email, password, code),
    changePassword: (oldPassword, password) =>
      changePassword(oldPassword, password)
  }
}

export default useAccount
