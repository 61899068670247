import React, { useState, useEffect } from 'react'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import { Container, Draggable } from 'react-smooth-dnd'
import Paper from '@material-ui/core/Paper'
import TourPointItem from './TourPointItem'
import TourPathItem from './TourPathItem'
import MediaButtons from './MediaButtons'
import {
  createNewPointJSON,
  isTourPoint,
  isInfoPoint,
  isSFXPoint,
  isViaPoint,
  isBurlovPoint
} from '../../utils'
import useApi from 'hooks/useApi'
import { useNavigate } from 'react-router'

const TourPageSideBar = ({ classes }) => {
  const [selected, setSelected] = useState()
  const navigate = useNavigate()
  const api = useApi()
  const tour = api.tour.working

  // useEffect(() => {
  //   setSelected(api.tour.view.data._id)
  // }, [api.tour.view.data])

  return (
    <Paper
      elevation={1}
      className={classes.sPaper}
      style={{
        maxHeight: '100vh',
        overflow: 'auto',
        marginBottom: 0,
        paddingBottom: 20
      }}
    >
      <div className={classes.toolbar}>
        <Button variant='contained' onClick={() => navigate('/projects')}>
          Tillbaka
        </Button>
      </div>

      <ListItem
        button
        key='TourBtn'
        style={false ? { background: 'LightBlue' } : {}}
        onClick={() => {
          navigate('editor')
        }}
      >
        <ListItemText primary='Ändra Rundan' />
      </ListItem>

      <Divider />

      <ListItem
        button
        key='TourViewBtn'
        style={false ? { background: 'LightBlue' } : {}}
        onClick={() => {
          navigate('view')
        }}
      >
        <ListItemText primary='Visa Rundan' />
      </ListItem>
      <Divider />
      {
        // <h3>Slingar</h3>
        // <Button
        //   className={classes.addBtn}
        //   variant='contained'
        //   color='default'
        //   onClick={() => {
        //     const init = createNewPathJSON()
        //     const json = {
        //       ...init,
        //       latitude: api.route.latitude,
        //       longitude: api.route.longitude
        //     }
        //     api.createTourBurlov(json)
        //   }}
        // >
        //   Lägg till ny slinga
        // </Button>
        // <h4>Slingar</h4>
        // <div>
        //   <Container>
        //     {tour.burlov?.map((value) => {
        //       const isSelected = value._id === selected
        //       return <TourBurlovItem value={value} selected={isSelected} />
        //     })}
        //   </Container>
        // </div>
        // <Divider />
      }
      <h3>Rundans punkter</h3>
      <Button
        className={classes.addBtn}
        variant='contained'
        color='default'
        onClick={() => {
          const init = createNewPointJSON()
          const json = {
            ...init,
            latitude: api.route.latitude,
            longitude: api.route.longitude
          }
          api.createTourPoint(json)
        }}
      >
        Lägg till ny punkt
      </Button>
      <br />
      {
        // <h4>Punkter</h4>
        // <Container>
        //   {tour.places?.map((value, index) => {
        //     const isSelected = value._id === selected
        //     return (
        //       <div key={index}>
        //         <TourPointItem value={value} selected={isSelected} />
        //       </div>
        //     )
        //   })}
        // </Container>
        // <br />
      }
      <Divider />
      <h4>Rund- och viapunkter</h4>
      <div>
        <Container onDrop={(e) => api.reorderTourPoint(e)}>
          {tour.places
            ?.filter((a) => a.ordernumber !== 0)
            .sort((a, b) => a.ordernumber - b.ordernumber)
            .map((value, index) => {
              if (isTourPoint(value) || isViaPoint(value)) {
                const isSelected = value._id === selected
                return (
                  <Draggable key={value._id}>
                    <TourPointItem
                      draggable
                      value={value}
                      selected={isSelected}
                    />
                  </Draggable>
                )
              }
              return null
            })}
        </Container>
      </div>
      <Divider />
      <br />
      <h4>Infopunkter</h4>
      <Container>
        {tour.places?.map((value, index) => {
          const isSelected = value._id === selected
          return isInfoPoint(value) ? (
            <div key={index}>
              <TourPointItem value={value} selected={isSelected} />
            </div>
          ) : null
        })}
      </Container>
      <Divider />
      <h4>Ljudpunkter</h4>
      <List>
        {tour.places?.map((value, index) => {
          if (isSFXPoint(value)) {
            const isSelected = value._id === selected
            return (
              <div key={index}>
                <TourPointItem value={value} selected={isSelected} />
              </div>
            )
          }
          return null
        })}
      </List>
      <Divider />
      <MediaButtons />
    </Paper>
  )
}

export default TourPageSideBar
