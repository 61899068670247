import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
  Map,
  InfoWindow,
  Marker,
  Circle,
  GoogleApiWrapper
} from 'google-maps-react'
import { useNavigate } from 'react-router'

import useApi from 'hooks/useApi'

const InfoWindowEx = (props) => {
  const infoWindowRef = React.createRef()
  const contentElement = document.createElement(`div`)
  useEffect(() => {
    ReactDOM.render(React.Children.only(props.children), contentElement)
    infoWindowRef.current.infowindow.setContent(contentElement)
  }, [props.children])
  return <InfoWindow ref={infoWindowRef} {...props} />
}

const TourViewer = ({ google }) => {
  const [state, setState] = useState({
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false
  })
  const navigate = useNavigate()

  const api = useApi()
  const { route } = api
  const data = api.tour.working

  const cords = {
    lat: route.selected.point.coordinates[1] ?? 0,
    lng: route.selected.point.coordinates[0] ?? 0
  }
  const mapWid = '100%'
  const mapHei = 600

  const onMarkerClick = (props, marker) => {
    const { value } = props
    console.log(value)
    console.log(marker)
    console.log(props)
    setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    })
  }
  const onInfoWindowClose = () =>
    setState({
      activeMarker: null,
      showingInfoWindow: false
    })

  const onInfoWindowClick = () => {
    console.log(state.selectedPlace.value)
    api.selectTourPoint(state.selectedPlace.value)
    navigate('../point')
  }

  const onMapClicked = () => {
    if (state.showingInfoWindow)
      setState({
        activeMarker: null,
        showingInfoWindow: false
      })
  }

  return (
    <div>
      <div>
        <Map
          google={google}
          zoom={12}
          containerStyle={{ position: 'relative' }}
          style={{
            width: mapWid,
            height: mapHei
          }}
          initialCenter={cords}
        >
          {data?.places?.map((value, index) => {
            const [longitude, latitude] = value?.point?.coordinates ?? [0, 0]
            return (
              <Circle
                radius={value.radius}
                center={{
                  lat: Number(latitude),
                  lng: Number(longitude)
                }}
                strokeColor='transparent'
                strokeOpacity={0}
                strokeWeight={5}
                fillColor='#FF0000'
                fillOpacity={0.2}
              />
            )
          })}
          {data?.places?.map((value, index) => {
            const [longitude, latitude] = value?.point?.coordinates ?? [0, 0]
            let iconSize = new google.maps.Size(55, 55)
            let urlPin =
              'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
            if (value.placeTypeId === 1) {
              urlPin =
                'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' +
                value.ordernumber +
                '|FE6256|000000'
              iconSize = new google.maps.Size(35, 55)
            } else if (value.placeTypeId === 4) {
              urlPin =
                'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' +
                value.ordernumber +
                '|fed156|000000'
              iconSize = new google.maps.Size(35, 55)
            } else if (value.placeTypeId === 3) {
              urlPin = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
            }
            return (
              <Marker
                value={value}
                title={api.getLanguageField(value.title)}
                onClick={onMarkerClick}
                key={value._id}
                name={api.getLanguageField(value.title)}
                position={{ lat: latitude, lng: longitude }}
                icon={{
                  url: urlPin,
                  scaledSize: iconSize
                }}
              />
            )
          })}
          <InfoWindowEx
            marker={state.activeMarker}
            onClose={onInfoWindowClose}
            visible={state.showingInfoWindow}
          >
            <div style={{ cursor: 'pointer' }} onClick={onInfoWindowClick}>
              <h4>{state.selectedPlace != null && state.selectedPlace.name}</h4>
            </div>
          </InfoWindowEx>
        </Map>
      </div>
    </div>
  )
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyA6jdWmCAWoFUMrfWoi9j6-tFpyVTz54ns'
})(TourViewer)
