import { ObjectID } from 'bson'

const checkMediaType = (label) => (v) => v.contentTypeGroup === label
export const isImageType = checkMediaType('IMAGE')
export const isSoundType = checkMediaType('SOUND')
export const isVideoType = checkMediaType('VIDEO')

const checkTourPointType = (i) => (v) => v.placeTypeId === i
export const isTourPoint = checkTourPointType(1)
export const isInfoPoint = checkTourPointType(2)
export const isSFXPoint = checkTourPointType(3)
export const isViaPoint = checkTourPointType(4)
export const isBurlovPoint = checkTourPointType(5)

export const tourPointTypesArray = Object.freeze(
  Object.seal([
    { placeTypeId: 1, name: 'Rundpunkt' },
    { placeTypeId: 2, name: 'Infopunkt' },
    { placeTypeId: 3, name: 'Ljudpunkt' },
    { placeTypeId: 4, name: 'Viapunkt' },
    { placeTypeId: 5, name: 'BurlovPunkt' }
  ])
)

export const getTourPointTypeString = (i) => {
  if (isTourPoint(i)) return tourPointTypesArray[0].name
  if (isInfoPoint(i)) return tourPointTypesArray[1].name
  if (isSFXPoint(i)) return tourPointTypesArray[2].name
  if (isViaPoint(i)) return tourPointTypesArray[3].name
  if (isBurlovPoint(i)) return tourPointTypesArray[4].name
}

export const createNewPointJSON = () => {
  const objid = new ObjectID()
  return {
    title: {
      dirty: false,
      data: []
    },
    placeTypeId: 1,
    ordernumber: 1000,
    htmlContent: {
      dirty: false,
      data: []
    },
    subcategory: {
      dirty: false,
      data: []
    },
    images: [],
    sounds: [],
    videos: [],
    _id: objid.toString(),
    latitude: '0',
    longitude: '0',
    radius: '15',
    numpadId: 0
  }
}

export const createNewPathJSON = () => {
  const objid = new ObjectID()
  return {
    title: {
      dirty: false,
      data: []
    },
    description: {
      dirty: false,
      data: []
    },
    subcategory: {
      dirty: false,
      data: []
    },
    image: '',
    illuminated: false,
    accesible: false,
    length: '0',
    time: '0',
    tourObjects: [],
    _id: objid.toString(),
    path: null
  }
}
