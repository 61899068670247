import { groupBy } from 'lodash'
import { isSFXPoint, isInfoPoint, isViaPoint, isTourPoint } from 'utils'
import {
  UPDATE_LANGUAGES,
  UPDATE_TOURPOINT,
  CHANGE_TOURPOINT_TYPE,
  REORDER_TOURPOINTS,
  REMOVE_TOURPOINT,
  CREATE_TOURPOINT,
  REORDER_TOUR_PATH,
  CREATE_TOUR_PATH,
  UPDATE_TOUR_PATH,
  REMOVE_TOUR_PATH,
  RESET_TOUR_STATE,
  UPDATE_TOUR_FROM_SERVER,
  SELECT_TOUR_PATH,
  SELECT_TOUR_POINT
} from 'symbols'
import { TOUR_MEDIA } from 'symbols'

const reorderPlaces = (arr) => {
  const { withoutOrder = [], unordered = [] } = groupBy(arr, (v) =>
    v.ordernumber === 0 ? 'withoutOrder' : 'unordered'
  )

  const ordered = unordered
    .sort((a, b) => a.ordernumber - b.ordernumber)
    .map((v, i) => {
      return { ...v, ordernumber: i + 1 }
    })
  return withoutOrder.concat(ordered)
}

const tourReducer = (state, action) => {
  const { working } = state
  const { type, payload } = action
  const idPredicate = (v) => v._id === id
  const { _id: id = null } = payload

  if (!typeof type === 'symbol')
    throw new Error('action.type is not of type Symbol')

  switch (type) {
    case UPDATE_TOUR_PATH: {
      const paths = working.paths.map((v) => {
        return v._id === id ? { ...v, ...payload } : { ...v }
      })

      return {
        ...state,
        working: { ...working, paths: paths },
        data: paths.find(idPredicate),
        dirty: true
      }
    }

    case REMOVE_TOUR_PATH: {
      let { data } = state
      const paths = working.paths.filter((v) => v._id !== id)

      const newPlaces = reorderPlaces(paths)

      if (data._id === payload._id) {
        data = null
      }

      return {
        ...state,
        working: { ...working, paths: newPlaces },
        data,
        dirty: true
      }
    }

    case UPDATE_LANGUAGES: {
      return {
        ...state,
        working: { ...working, dirtyLanguage: true },
        dirty: true
      }
    }

    case UPDATE_TOURPOINT: {
      const places = working.places.map((v) => {
        return v._id === id ? { ...v, ...payload } : { ...v }
      })

      return {
        ...state,
        working: { ...working, places },
        data: places.find(idPredicate),
        dirty: true
      }
    }

    case CHANGE_TOURPOINT_TYPE: {
      let dirty = true
      const data = { ...payload }
      const [prev_type] = working.places.filter(idPredicate)

      if (data.placeTypeId === prev_type.placeTypeId) {
        dirty = false
      }
      if (
        (isTourPoint(data) || isViaPoint(data)) &&
        (isTourPoint(prev_type) || isViaPoint(prev_type))
      ) {
        /* do nothing - either no change or just from TourPoint to ViaPoint or
         * the otherway around*/
      } else if (
        (isTourPoint(data) || isViaPoint(data)) &&
        (isSFXPoint(prev_type) || isInfoPoint(prev_type))
      ) {
        /* We have an unordered point becoming ordered */
        // Set new point's ordernumber to something large
        data.ordernumber = 10000
      } else if (
        (isSFXPoint(data) || isInfoPoint(data)) &&
        (isTourPoint(prev_type) || isViaPoint(prev_type))
      ) {
        /* We have an ordered point becoming unordered */
        // Set ordernumber to 0 and empty imageAwsIdArray and moviesAwsIdArray
        data.ordernumber = 0
      }

      // sfx and via points cant have images or movies
      if (isSFXPoint(data) || isViaPoint(data)) {
        data.imageAwsIdArray = []
        data.moviesAwsIdArray = []
      }

      const _places = working.places.map((v) => {
        return v._id === id ? { ...data } : { ...v }
      })

      const places = reorderPlaces(_places)

      return {
        ...state,
        working: { ...working, places },
        data: places.find(idPredicate),
        dirty
      }
    }

    case REORDER_TOURPOINTS: {
      let dirty = false
      // fix disparity between indexes and ordernumbers
      const removeIndex = payload.removedIndex + 1
      const addIndex = payload.addedIndex + 1
      const places = working.places.map((i) => {
        // ordernumber 0 means not meant to be ordered
        if (i.ordernumber === 0) {
          return i
        }
        // if outside both addIndex and removeIndex nothing changes
        if (
          (i.ordernumber < addIndex && i.ordernumber < removeIndex) ||
          (i.ordernumber > addIndex && i.ordernumber > removeIndex)
        ) {
          return i
        }

        // empty move
        if (addIndex === removeIndex) {
          return i
        }

        // moving item from top further down
        if (addIndex > removeIndex) {
          if (i.ordernumber === removeIndex) {
            dirty = true
            return { ...i, ordernumber: addIndex }
          }
          if (i.ordernumber <= addIndex) {
            dirty = true
            return { ...i, ordernumber: i.ordernumber - 1 }
          }
        }
        // moving item up the list
        if (addIndex < removeIndex) {
          if (i.ordernumber === removeIndex) {
            dirty = true
            return { ...i, ordernumber: addIndex }
          }
          if (i.ordernumber >= addIndex) {
            dirty = true
            return { ...i, ordernumber: i.ordernumber + 1 }
          }
        }
      })
      return { ...state, dirty, working: { ...working, places } }
    }

    case REMOVE_TOURPOINT: {
      let { data } = state
      const places = working.places.filter((v) => v._id !== id)

      const newPlaces = reorderPlaces(places)

      if (data._id === payload._id) {
        data = null
      }

      return {
        ...state,
        working: { ...working, places: newPlaces },
        data,
        dirty: true
      }
    }

    case CREATE_TOURPOINT: {
      const places = [...working.places, { ...payload, new: true }]
      return {
        ...state,
        working: { ...working, places },
        data: places.find(idPredicate),
        dirty: true
      }
    }

    case CREATE_TOUR_PATH: {
      const paths = [...working.paths, { ...payload, new: true }]
      return {
        ...state,
        working: { ...working, paths },
        data: paths.find(idPredicate),
        dirty: true
      }
    }

    case SELECT_TOUR_PATH: {
      return {
        ...state,
        data: working.paths.find(idPredicate)
      }
    }

    case RESET_TOUR_STATE: {
      let { data } = state
      const place = state.original.places.find((v) => v._id === data?._id)
      const path = state.original.paths.find((v) => v._id === data?._id)
      data = place ?? path // data is null if neither place of path is found.. that is intentional
      return { ...state, working: { ...state.original }, data, dirty: false }
    }

    case UPDATE_TOUR_FROM_SERVER: {
      let { data } = state
      const place = state.working?.places?.find((v) => v._id === data?._id)
      const path = state.working?.paths?.find((v) => v._id === data?._id)
      data = place ?? path ?? data
      return { ...state, ...payload, data }
    }

    case SELECT_TOUR_POINT: {
      return {
        ...state,
        data: working.places.find(idPredicate)
      }
    }

    case TOUR_MEDIA: {
      return {
        ...state,
        data: {
          mediaData: working.media,
          mediaType: payload
        }
      }
    }

    default:
      throw new Error(`action.type not found ${action.type.toString()}`)
  }
}

export default tourReducer
