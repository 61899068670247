import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Button from '@material-ui/core/Button'
// import './GlobalStyle.css'
import ChangeTourPointTypeDialog from './ChangeTourPointTypeDialog'
import {
  PaperMapAndLocation,
  PaperCategory,
  PaperSubCategory,
  PaperEditor,
  PaperImageSelect,
  PaperSoundSelect,
  PaperVideoSelect,
  PaperImage360Select
} from '../PaperComponents'
import {
  isImageType,
  isSoundType,
  isVideoType,
  isBurlovPoint,
  isTourPoint,
  isInfoPoint,
  isSFXPoint,
  isViaPoint,
  getTourPointTypeString
} from '../../utils'
import useApi from 'hooks/useApi'

const mapW = 400
const mapH = 400

const TourPoint = ({
  data,
  onImageChange,
  onImage360Change,
  onSoundChange,
  onVideoChange
}) => {
  const classes = useStyles()
  const api = useApi()
  const { tour, getLanguageField } = api
  console.log({ data })

  return (
    <div>
      <PaperEditor data={data} />
      <PaperImageSelect
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImageChange}
      />
      <PaperImage360Select
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImage360Change}
      />
      <PaperSoundSelect
        data={data}
        tourId={tour.working._id}
        sounds={tour.working.media.filter(isSoundType)}
        onSoundChange={onSoundChange}
      />
      <PaperVideoSelect
        data={data}
        tourId={tour.working._id}
        videos={tour.working.media.filter(isVideoType)}
        onVideoChange={onVideoChange}
      />
    </div>
  )
}

const InfoPoint = ({
  data,
  onImageChange,
  onImage360Change,
  onSoundChange,
  onVideoChange
}) => {
  const classes = useStyles()
  const api = useApi()
  const { tour, getLanguageField } = api

  return (
    <div>
      <PaperEditor data={data} />
      <PaperImageSelect
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImageChange}
      />
      <PaperImage360Select
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImage360Change}
      />
      <PaperSoundSelect
        data={data}
        tourId={tour.working._id}
        sounds={tour.working.media.filter(isSoundType)}
        onSoundChange={onSoundChange}
      />
      <PaperVideoSelect
        data={data}
        tourId={tour.working._id}
        videos={tour.working.media.filter(isVideoType)}
        onVideoChange={onVideoChange}
      />
    </div>
  )
}

const SFXPoint = ({ data, onSoundChange }) => {
  const classes = useStyles()
  const api = useApi()
  const { tour, getLanguageField } = api
  return (
    <div>
      <PaperSoundSelect
        data={data}
        tourId={tour.working._id}
        sounds={tour.working.media.filter(isSoundType)}
        onSoundChange={onSoundChange}
      />
    </div>
  )
}

const ViaPoint = ({ data }) => {
  const classes = useStyles()
  const api = useApi()
  const { tour, getLanguageField } = api

  return <div></div>
}

const BurlovPoint = ({
  data,
  onImageChange,
  onImage360Change,
  onCategoryChange,
  onSubCategoryChange
}) => {
  const classes = useStyles()
  const api = useApi()
  const { tour, getLanguageField } = api
  const { placesCategories } = tour.working

  return (
    <div>
      <PaperEditor data={data} />
      <PaperImageSelect
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImageChange}
      />
      <PaperImage360Select
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImage360Change}
      />
      <PaperCategory
        data={data}
        categories={placesCategories}
        onCategoryChange={onCategoryChange}
      />
      <PaperSubCategory data={data} onSubCategoryChange={onSubCategoryChange} />
    </div>
  )
}
const TourPointEditor = ({ newPoint }) => {
  const classes = useStyles()
  const api = useApi()
  const { data } = api.tour
  console.log(data)
  const { tour, getLanguageField } = api

  const [typeDialogOpen, setTypeDialogOpen] = useState(false)

  const setOnMediaChange = (field) => (value) => {
    api.updateTourPointMedia(field, value, data)
  }

  const onImageChange = setOnMediaChange('images')
  const onImage360Change = setOnMediaChange('images360')
  const onSoundChange = setOnMediaChange('sounds')
  const onVideoChange = setOnMediaChange('videos')

  const onTitleChange = (e) => {
    api.updateTourPointTitle(e.target.value, data)
  }

  const onSimpleFieldChange = (field) => (e) => {
    api.updateTourPointSimpleField(field, e.target.value, data)
  }

  const onCategoryChange = onSimpleFieldChange('category')

  const onSubCategoryChange = (e) => {
    api.updateTourPointSubCategory(e.target.value, data)
  }

  const onGetLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log('Latitude is :', position.coords.latitude)
      console.log('Longitude is :', position.coords.longitude)
      api.updateTourPointLocation(
        [position.coords.longitude, position.coords.latitude],
        data
      )
    })
  }

  const onLatitudeChange = (e) => {
    api.updateTourPointLocation(
      [data.point.coordinates[0], parseFloat(e.target.value)],
      data
    )
  }

  const onLongitudeChange = (e) => {
    api.updateTourPointLocation(
      [parseFloat(e.target.value), data.point.coordinates[1]],
      data
    )
  }

  const onRadiusChange = onSimpleFieldChange('radius')

  const onNumpadChange = (e) => {
    const number = e.target.value === '' ? '' : parseInt(e.target.value)
    if ((typeof number === 'number' && !isNaN(number)) || number === '') {
      api.updateTourPointNumPad(number, data)
    }
  }

  const onMapsMarkerDragEnd = useCallback(
    (coordinates) => {
      const { latLng } = coordinates
      api.updateTourPointLocation([latLng.lng(), latLng.lat()], data)
    },
    [data]
  )

  const onChangeTourType = (value) => {
    if (value) {
      let newData = data
      newData.new && delete newData.new
      api.updateTourPointType(value, newData)
    } else {
      data.new && api.deleteTourPoint(data)
    }
    setTypeDialogOpen(false)
  }

  const onClickChangeTourType = () => {
    setTypeDialogOpen(true)
  }

  return (
    <>
      <div className={classes.main}>
        <div className={classes.r1}>
          <div className={classes.r1L}>
            <Paper elevation={1} className={classes.mPaper}>
              <div
                style={{
                  display: 'flex',
                  width: '30%',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <div style={{ paddingRight: 10 }}>
                  <h3>Punkt Typ:</h3>
                </div>
                <div>
                  <Button variant='contained' onClick={onClickChangeTourType}>
                    {getTourPointTypeString(data)}
                  </Button>
                </div>
              </div>
            </Paper>
            <Paper elevation={1} className={classes.mPaper}>
              <h3>Titel:</h3>
              <input
                className={classes.singleLineTxtField}
                type='text'
                id='title'
                name='title'
                value={getLanguageField(data.title)}
                onChange={onTitleChange}
              />
            </Paper>
            {isTourPoint(data) && (
              <TourPoint
                data={data}
                onImageChange={onImageChange}
                onImage360Change={onImage360Change}
                onSoundChange={onSoundChange}
                onVideoChange={onVideoChange}
              />
            )}
            {isInfoPoint(data) && (
              <InfoPoint
                data={data}
                onImageChange={onImageChange}
                onImage360Change={onImage360Change}
                onSoundChange={onSoundChange}
                onVideoChange={onVideoChange}
              />
            )}
            {isSFXPoint(data) && (
              <SFXPoint data={data} onSoundChange={onSoundChange} />
            )}
            {isViaPoint(data) && <ViaPoint data={data} />}
            {isBurlovPoint(data) && (
              <BurlovPoint
                data={data}
                onImageChange={onImageChange}
                onImage360Change={onImage360Change}
                onCategoryChange={onCategoryChange}
                onSubCategoryChange={onSubCategoryChange}
              />
            )}
          </div>
          <div className={classes.r1R}>
            <PaperMapAndLocation
              data={data}
              onMapsMarkerDragEnd={onMapsMarkerDragEnd}
              onLatitudeChange={onLatitudeChange}
              onLongitudeChange={onLongitudeChange}
              onGetCurrentLocation={onGetLocation}
              onRadiusChange={onRadiusChange}
            ></PaperMapAndLocation>
            {(isInfoPoint(data) || isTourPoint(data)) && (
              <>
                <h3>Numpad-Nummer:</h3>
                <input
                  className={classes.singleLineTxtField}
                  type='text'
                  id='numpadId'
                  name='numpadId'
                  value={data.numpadId}
                  onChange={onNumpadChange}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <ChangeTourPointTypeDialog
        open={typeDialogOpen}
        value={data.placeTypeId}
        onClose={onChangeTourType}
      />
    </>
  )
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  r1L: {
    flex: `calc(100% - ${mapW}px - 10px)`,
    marginRight: 20
  },
  r1R: {
    flex: mapW,
    marginTop: 0,
    marginRight: '0px',
    flexDirection: 'column'
  },
  underMap: {
    marginTop: mapH + 20,
    width: '100%'
  },

  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },

  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},

  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  mDropper: {
    width: '80%',
    marginTop: 10,
    marginBottom: 10,
    padding: 20,
    paddingTop: 5,
    fontSize: 16
  }
})

export default TourPointEditor
