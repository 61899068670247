import React, { useReducer } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet
} from 'react-router-dom'
import './App.css'

import tourReducer from 'reducers/tourReducer'
import routeReducer from 'reducers/routeReducer'
import accountReducer from 'reducers/accountReducer'

import ToursListPage from 'components/ToursListPage'
import TourPage from './TourPage'
import LoginPage from 'components/LoginPage'
import TourViewer from 'components/TourViewer'
import TourEditor from 'components/TourEditor'
import TourPathEditor from 'components/TourPathEditor'
import TourPointEditor from 'components/TourPointEditor'
import TourMedia from 'components/MediaPage'
import UserPage from 'components/Settings'
import useApi from 'hooks/useApi'

export const TourContext = React.createContext({})
export const RouteContext = React.createContext({})
export const AccountContext = React.createContext({})

const initialAccountState = {
  user: {},
  isLoggedIn: false
}

const initialRouteState = {
  original: {},
  working: {},
  hasErrors: false,
  selected: null,
  dirty: false
}

const initialTourState = {
  original: {},
  working: {},
  hasErrors: false,
  dirty: false
}

const ProtectedRoute = ({ redirectPath, children }) => {
  const api = useApi()
  if (api.account.user?.id == null) {
    return <Navigate to={redirectPath} replace />
  }
  return children ? children : <Outlet />
}

const App = () => {
  const [tour, dispatchTour] = useReducer(tourReducer, initialTourState)
  const [route, dispatchRoute] = useReducer(routeReducer, initialRouteState)
  const [account, dispatchAccount] = useReducer(
    accountReducer,
    initialAccountState
  )

  return (
    <AccountContext.Provider value={{ account, dispatchAccount }}>
      <RouteContext.Provider value={{ route, dispatchRoute }}>
        <TourContext.Provider value={{ tour, dispatchTour }}>
          <BrowserRouter>
            <Routes>
              <Route index element={<LoginPage />} />
              <Route path='login' element={<LoginPage />} />
              <Route element={<ProtectedRoute redirectPath={'/login'} />}>
                <Route path='projects' element={<ToursListPage />} />
                <Route path='project'>
                  <Route path=':id' element={<TourPage />}>
                    <Route index path='view' element={<TourViewer />} />
                    <Route path='editor' element={<TourEditor />} />
                    <Route path='point' element={<TourPointEditor />} />
                    <Route path='path' element={<TourPathEditor />} />
                    <Route path='media' element={<TourMedia />} />
                  </Route>
                </Route>
                <Route path='settings' element={<UserPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </TourContext.Provider>
      </RouteContext.Provider>
    </AccountContext.Provider>
  )
}

export default App
