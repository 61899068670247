import React, { useEffect, useReducer, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Outlet, useNavigate } from 'react-router'
import useApi from 'hooks/useApi'

const drawerWidth = 240

const TopMenu = () => {
  const classes = useStyles()

  const [saving, setSaving] = useState(false)

  const navigate = useNavigate()
  const api = useApi()
  const { route, tour } = api

  const onReturnToTourList = () => {
    api.deselectRoute()
    navigate('/projects')
  }

  useEffect(() => {
    const save = async () => {
      await api.saveRoute()
      await api.saveTour()
    }
    if (saving === true) {
      save()
      setSaving(false)
    }
  }, [saving])

  const onClickSave = () => setSaving(true)
  const onClickUndo = () => {
    setSaving(false)
    api.resetRoute()
    api.resetTour()
  }

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div>
            <Typography variant='h6' noWrap>
              <Button className={classes.backBtn} onClick={onReturnToTourList}>
                &#8678; Projects
              </Button>
              {route.selected?.title.data
                .filter((i) => i.language_code === 'sv')
                .map((i) => i.text)}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            {(tour.dirty || route.dirty) && saving === false && (
              <>
                <Button
                  className={classes.alertButton}
                  variant='contained'
                  color='primary'
                  onClick={onClickSave}
                >
                  Spara
                </Button>
                <Button
                  className={classes.alertButton}
                  color='primary'
                  variant='contained'
                  onClick={onClickUndo}
                >
                  Ångra
                </Button>
              </>
            )}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden'
  },
  appBar: {
    width: '100%',
    height: 60,
    color: '#444',
    marginLeft: drawerWidth,
    backgroundColor: '#ccdbf0',
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
    top: 47
  },
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxHeight: '100vh',
    overflow: 'auto'
  },
  mainCon: {
    align: 'left',
    textAlign: 'left',
    minHeight: 300
  },
  backBtn: {
    paddingLeft: 10,
    align: 'left',
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 500,
    marginLeft: -10,
    textTransform: 'none'
  },
  addBtn: {
    align: 'center',
    textAlign: 'center',
    fontSize: 14,
    width: '80%',
    textTransform: 'none',
    margin: 'auto',
    backgroundColor: '#528adb',
    color: 'white',
    marginBottom: 10
  },
  sPaper: {
    width: drawerWidth,
    marginBottom: 20,
    padding: 5,
    paddingTop: 5,
    backgroundColor: 'default',
    flexShrink: 0
  },
  alertButton: {
    backgroundColor: 'orange',
    color: 'blue',
    margin: '2px 10px 2px 10px'
  }
}))

export default TopMenu
